.loader {
  border-top: 3px solid #5a67d8;
  border-right: 3px solid #5a67d8;
  border-bottom: 3px solid #5a67d8;
  animation: spin 500ms linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
	border-radius: 10px;
	background-color: #ffffff;
}

::-webkit-scrollbar
{
	width: 8px;
	height: 8px;
	background-color: #ffffff;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
	background-color: #e2e8f0;
}

#clipboard {
  fill: none;
  stroke-width: 3;
  stroke: #a0aec0;
	cursor: pointer;
}

#clipboard:hover {
  /* background-color: pink; */
  stroke: #4a5568;
}
